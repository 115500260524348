<template>
  <section class="index-youtube" id="youtube">
    <h2 class="heading-img">
      <img src="@/assets/img/heading_youtube.png" alt="Youtube" />
    </h2>
    <div class="inner-box">
      <p v-if="Object.keys(latest).length" class="date pc">{{ latest.published_at | format_date }} 更新</p>
      <ul class="card-list">
        <li class="card" v-for="item in items" :key="item.id">
          <div class="embedded" v-if="mappedMedia[item.id].yt && mappedMedia[item.id].yt.length">
            <iframe
              type="text/html"
              :src="'https://www.youtube.com/embed/' + mappedMedia[item.id].yt[0].video_id"
              width="1280"
              height="720"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </li>
      </ul>
      <prev-next
        :scroll-to-element="'#news'"
        :currentPage="pagination.current_page"
        :totalPage="pagination.total_pages"
        :targetPage="targetPage"
      />
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import utils from "@/mixins/utils";
import PrevNext from "@/components/pagination/PrevNext";

export default {
  name: "Youtube",
  components: { PrevNext },
  mixins: [utils],
  data() {
    return {
      items: [],
      latest: {},
      pagination: {
        current_page: 1,
        total_pages: 1,
        per_page: 4,
      },
      targetPage: "ytPage",
    };
  },
  computed: {
    mappedMedia() {
      return this.$_mappingMedia(this.items);
    },
  },
  filters: {
    format_date(date) {
      return dayjs(date).format("YYYY.MM.DD");
    },
  },
  watch: {
    "$route.query.ytPage"(to) {
      if (to) {
        this.fetchYoutubeData(to);
      }
    },
  },
  created() {
    const ytPage = this.$route.query.ytPage || 1;
    this.fetchYoutubeData(ytPage);
    this.fetchLatestData();
  },
  methods: {
    fetchYoutubeData(ytPage) {
      this.$http
        .get(`youtube?page=${ytPage}&perPage=${this.pagination.per_page}`)
        .then((res) => {
          this.items = res.data.data;
          this.pagination = res.data.meta.pagination;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchLatestData() {
      this.$http
        .get(`youtube?page=1&perPage=1`)
        .then((res) => {
          this.latest = res.data.data[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.embedded {
  width: 100%;
  aspect-ratio: 16 / 9;
  background: black;
  iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
