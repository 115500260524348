<template>
  <div>
    <main>
      <section class="index-kv">
        <img class="sp" src="@/assets/img/index_kv01.png" alt="イトマンマンSPトップ画像" />
        <img class="pc" src="@/assets/img/index_kv02.png" alt="イトマンマンPCトップ画像" />
      </section>
      <Youtube />
      <News />
      <Event />
      <Character />
      <section class="index-goods" id="goods">
        <h2 class="heading-img"><img src="@/assets/img/heading_goods.png" alt="Goods" /></h2>
        <div class="bn">
          <a href="https://itomanman.base.shop/" target="_new">
            <img class="pure-img" src="@/assets/img/bn_shop.jpg" alt="イトマンマンオフィシャルショップ" />
          </a>
        </div>
      </section>
      <Twitter />
      <Comics />
    </main>
  </div>
</template>
<script>
import Youtube from "@/components/Youtube";
import News from "@/components/News";
import Event from "@/components/Event";
import Character from "@/components/Character";
import Twitter from "@/components/Twitter";
import Comics from "@/components/Comics";

export default {
  name: "Index",
  metaInfo: {
    title: "トップ",
  },
  components: {
    Youtube,
    News,
    Event,
    Character,
    Twitter,
    Comics,
  },
  mounted() {
    if (this.$route.hash) {
      this.scrollToHash();
    }
  },
  methods: {
    scrollToHash() {
      const hash = this.$route.hash;
      this.$nextTick(() => {
        this.$scrollTo(hash, 500);
      });
    },
  },
};
</script>
