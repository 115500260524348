import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import Index from '@/views/Index.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
