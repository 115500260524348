<template>
  <footer class="g-footer">
    <div class="g-footer-wrap">
      <div class="g-footer-info">
        <p>運営情報</p>
        <p>イトマンマンプロジェクト運営委員会</p>
        <p>info@itomanman.com</p>
        <span>イベント、CM、コラボ、なんでもご相談ください。</span>
      </div>
      <ul class="sns-list">
        <li v-for="(link, i) in snsLinks" :key="i" :class="link.class">
          <a :href="link.url" target="_new">
            <img :src="link.img" />
          </a>
        </li>
      </ul>
      <div class="g-footer-sponsor">
        <agile :options="slideOptions" v-if="items.length">
          <div v-for="item in items" :key="item.id" class="slide">
            <a v-if="item.url" :href="item.url" :target="item.target">
              <img :src="mappedMedia[item.id].image[0].url" :alt="item.title" />
            </a>
            <img v-else :src="mappedMedia[item.id].image[0].url" :alt="item.title" />
          </div>
        </agile>
      </div>
      <div class="g-footer-logo">
        <img class="pure-img" src="@/assets/img/logo.png" alt="イトマンマンロゴ" />
      </div>
    </div>
    <p class="g-footer-copy">&copy;2021 OE CO., LTD.</p>
  </footer>
</template>

<script>
import { VueAgile } from "vue-agile";
import utils from "@/mixins/utils";

export default {
  name: "Footer",
  components: {
    agile: VueAgile,
  },
  mixins: [utils],
  data() {
    return {
      items: [],
      slideOptions: {
        autoplay: true,
        autoplaySpeed: 10000,
        fade: false,
        dots: true,
        centerMode: false,
        navButtons: false,
        infinite: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      snsLinks: {
        instagram: {
          url: "https://www.instagram.com/itomanman/",
          img: require("@/assets/img/ico_instagram.png"),
          class: "",
        },
        twitter: {
          url: "https://twitter.com/itomanman2021/",
          img: require("@/assets/img/ico_twitter.png"),
          class: "pc",
        },
        facebook: {
          url: "https://www.facebook.com/Itomanman2021/",
          img: require("@/assets/img/ico_facebook.png"),
          class: "",
        },
      },
    };
  },
  computed: {
    mappedMedia() {
      return this.$_mappingMedia(this.items);
    },
  },
  created() {
    this.fetchSponsorData();
  },
  methods: {
    fetchSponsorData() {
      this.$http
        .get(`sponsor`)
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.g-footer-info {
  p {
    &:last-of-type {
      margin-top: 0.3em;
      margin-bottom: 0.3em;
      font-size: 20px;
    }
  }
}
.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 96%;
  }
}
</style>
<!-- Scoped breaks plugin style -->
<style lang="scss">
/* vue-agile's style */
.agile {
  &__dots {
    bottom: 10px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  &__dot {
    margin: 0 10px;
    button {
      background-color: transparent;
      border: 1px solid white;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: 0.3s;
      width: 10px;
    }
    &--current,
    &:hover {
      button {
        background-color: white;
      }
    }
  }
}
</style>
