export default {
    methods: {
        $_mappingMedia: function (items) {
            const mappedArr = items.map(item => {
                const tagsObj = item.media.reduce((tagsInto, media) => {
                    if (!tagsInto[media.tag]) {
                        tagsInto[media.tag] = [];
                    }
                    tagsInto[media.tag].push(media);
                    return tagsInto;
                }, {});
                return [item.id, tagsObj];
            });
            return Object.fromEntries(mappedArr);
        },
    },
}
