<template>
  <header class="g-header">
    <div class="g-header-inner">
      <button class="g-header-nav-trg" @click="fadeToggle">
        <img src="@/assets/img/ico_trg.png" />
      </button>
    </div>
    <transition name="fade">
      <nav class="g-header-nav" @click.self="fadeToggle" v-if="show">
        <ul class="g-header-nav-list">
          <li v-for="(link, i) in headerLinks" :key="i">
            <router-link :to="link.to" v-scroll-to="{ el: link.scrollTo, onStart:fadeToggle }" exact
              >●&nbsp;{{ link.text }}</router-link
            >
          </li>
        </ul>
      </nav>
    </transition>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      show: false,
      headerLinks: [
        {
          text: "YouTube Channel",
          scrollTo: "#youtube",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#youtube",
          },
        },
        {
          text: "What's News",
          scrollTo: "#news",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#news",
          },
        },
        {
          text: "Event",
          scrollTo: "#event",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#event",
          },
        },
        {
          text: "Character",
          scrollTo: "#character",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#character",
          },
        },
        {
          text: "Goods",
          scrollTo: "#goods",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#goods",
          },
        },
        {
          text: "X（旧Twitter）",
          scrollTo: "#twitter",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#twitter",
          },
        },
        {
          text: "Comics",
          scrollTo: "#comics",
          to: {
            name: "index",
            query: this.$route.query,
            hash: "#comics",
          },
        },
      ],
    };
  },
  methods: {
    fadeToggle: function () {
      this.show = !this.show
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
