<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  metaInfo() {
    return {
      title: this.$defaultTitle,
      titleTemplate: this.$defaultTitleTemplate,
    };
  },
};
</script>


<style lang="scss">
</style>
