<template>
  <section class="index-character" id="character">
    <svg
      class="sp"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 750 1066"
      preserveAspectRatio="xMinYMin meet"
    >
      <image
        width="750"
        height="1066"
        class="pure-img"
        href="@/assets/img/index_cheracter.png"
      />
      <rect
        class="click-area"
        @click="showModal(require('@/assets/img/popup01.png'))"
        x="90"
        y="230"
        width="560"
        height="300"
      ></rect>
      <rect
        class="click-area"
        @click="showModal(require('@/assets/img/popup02.png'))"
        x="90"
        y="570"
        width="560"
        height="300"
      ></rect>
    </svg>
    <svg
      class="pc"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 546"
      preserveAspectRatio="xMinYMin meet"
    >
      <image
        width="1000"
        height="546"
        class="pure-img"
        href="@/assets/img/index_cheracter_pc.png"
      />
      <rect
        class="click-area"
        @click="showModal(require('@/assets/img/popup01.png'))"
        x="80"
        y="160"
        width="410"
        height="220"
      ></rect>
      <rect
        class="click-area"
        @click="showModal(require('@/assets/img/popup02.png'))"
        x="510"
        y="160"
        width="410"
        height="220"
      ></rect>
    </svg>
    <modal
      name="character-modal"
      :adaptive="true"
      height="auto"
      width="90%"
      :max-width="381"
      transition="fade"
      :scrollable="true"
      @before-open="modal.isOpened = true"
      @before-close="modal.isOpened = false"
    >
      <div v-scroll-lock="modal.isOpened">
        <img :src="modal.src" />
        <button
          type="button"
          class="modal-close"
          @click="$modal.hide('character-modal')"
        >
          &times;
        </button>
      </div>
    </modal>
    <transition name="fade" appear>
      <div
        v-show="modal.isLoading"
        class="spinner-loading"
      >
        <font-awesome-icon
          :icon="['fas', 'spinner']"
          size="3x"
          spin
        ></font-awesome-icon>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: "Character",
  components: {},
  data() {
    return {
      items: [],
      modal: {
        src: "",
        isLoading: false,
        isOpened: false,
      },
    };
  },
  methods: {
    showModal(filePath) {
      this.modal.isLoading = true;
      const preloadImage = new Image();
      preloadImage.onload = () => {
        this.$modal.show("character-modal");
        this.modal.isLoading = false;
      };
      preloadImage.src = this.modal.src = filePath;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  .click-area {
    opacity: 0;
    cursor: pointer;
  }
}
</style>
