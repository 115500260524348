<template>
  <section class="index-twitter sp" id="twitter">
    <h2 class="heading-img">
      <img src="@/assets/img/heading_twitter.png" alt="Twitter"/>
    </h2>
    <a class="twitter-timeline" data-lang="ja" data-height="972" data-dnt="false" :data-tweet-limit="tweetLimit"
       href="https://twitter.com/itomanman2021?ref_src=twsrc%5Etfw">Tweets by itomanman2021</a>
    <script async defer src="https://platform.twitter.com/widgets.js" type="application/javascript"
            charset="utf-8"></script>
  </section>
</template>

<script>

export default {
  name: 'Twitter',
  data() {
    return {
      tweetLimit: process.env.VUE_APP_TWEET_LIMIT,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
