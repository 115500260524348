<template>
  <section class="index-event" id="event">
    <h2 class="heading-img">
      <img src="@/assets/img/heading_event.png" alt="Event" />
    </h2>
    <div class="inner-box">
      <p class="date">{{ latest.published_at | format_date }} 更新</p>
      <ul class="card-list">
        <li class="card" v-for="item in items" :key="item.id">
          <div
            v-if="mappedMedia[item.id].image && mappedMedia[item.id].image.length"
            class="tmb of"
          >
            <a v-if="item.url" :href="item.url" :target="item.target">
              <img class="pure-img" :src="mappedMedia[item.id].image[0].url" :alt="item.title" />
              <span class="link-icon"><font-awesome-icon :icon="['fas', 'link']"></font-awesome-icon></span>
            </a>
            <img v-else class="pure-img" :src="mappedMedia[item.id].image[0].url" :alt="item.title" />
          </div>
          <div class="txt">
            <p class="ttl">{{ item.title }}</p>
            <p class="date">{{ item.published_at | format_date }}</p>
            <p class="txt-body">{{ item.body }}</p>
          </div>
          <button class="btn" type="button" @click="showModal(item)">
            MORE
          </button>
        </li>
        <modal
          name="event-modal"
          :adaptive="true"
          :scrollable="true"
          height="auto"
          width="75%"
          :max-width="800"
          transition="fade"
          @before-open="modal.isOpened = true"
          @before-close="modal.isOpened = false"
        >
          <div class="event-modal">
            <div v-if="modal.item.src" class="img">
              <a
                v-if="modal.item.url"
                :href="modal.item.url"
                :target="modal.item.target"
              >
                <img :src="modal.item.src" :alt="modal.item.title" />
                <span class="link-icon"><font-awesome-icon :icon="['fas', 'link']"></font-awesome-icon></span>
              </a>
              <img v-else :src="modal.item.src" :alt="modal.item.title" />
            </div>
            <div class="txt">
              <p class="ttl">{{ modal.item.title }}</p>
              <p class="date">{{ modal.item.published_at | format_date }}</p>
              <p class="txt-body">{{ modal.item.body }}</p>
            </div>
            <button
              type="button"
              class="modal-close"
              @click="$modal.hide('event-modal')"
            >
              &times;
            </button>
          </div>
        </modal>
        <transition name="fade" appear>
          <div
            v-show="modal.isLoading"
            class="spinner-loading"
          >
            <font-awesome-icon
              :icon="['fas', 'spinner']"
              size="3x"
              spin
            ></font-awesome-icon>
          </div>
        </transition>
      </ul>
      <prev-next
        :scroll-to-element="'#event'"
        :currentPage="pagination.current_page"
        :totalPage="pagination.total_pages"
        :targetPage="targetPage"
      />
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import utils from "@/mixins/utils";
import PrevNext from "@/components/pagination/PrevNext";

export default {
  name: "Event",
  components: { PrevNext },
  mixins: [utils],
  data() {
    return {
      items: [],
      latest: {},
      pagination: {
        current_page: 1,
        total_pages: 1,
        per_page: 4,
      },
      modal: {
        item: {
          src: null,
        },
        isLoading: false,
        isOpened: false,
      },
      targetPage: "eventPage",
    };
  },
  computed: {
    mappedMedia() {
      return this.$_mappingMedia(this.items);
    },
  },
  filters: {
    format_date(date) {
      return dayjs(date).format("YYYY.MM.DD");
    },
  },
  watch: {
    "$route.query.eventPage"(to) {
      if (to) {
        this.fetchEventData(to);
      }
    },
  },
  created() {
    const eventPage = this.$route.query.eventPage || 1;
    this.fetchEventData(eventPage);
    this.fetchLatestData();
  },
  methods: {
    fetchEventData(eventPage) {
      this.$http
        .get(`event?page=${eventPage}&perPage=${this.pagination.per_page}`)
        .then((res) => {
          this.items = res.data.data;
          this.pagination = res.data.meta.pagination;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchLatestData() {
      this.$http
        .get(`event?page=1&perPage=1`)
        .then((res) => {
          this.latest = res.data.data[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showModal(item) {
      this.modal.item = Object.assign({}, item);
      this.modal.isLoading = true;
      if (item.media.length) {
        const preloadImage = new Image();
        preloadImage.onload = () => {
          this.$modal.show("event-modal");
          this.modal.isLoading = false;
        };
        preloadImage.src = this.modal.item.src =
          this.mappedMedia[item.id].image[0].url;
      } else {
        this.$modal.show("event-modal");
        this.modal.item.src = null;
        this.modal.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index-event {
  .card {
    .btn {
      display: block;
      width: 5.5rem;
      margin: 0.2em 0.2em 0.2em auto;
      color: #585858;
      font-size: 90%;
      line-height: 3.2rem;
      border-radius: 0.25em;
      border: 1px solid;
      text-align: center;
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.event-modal {
  width: auto;
  padding: 1em;
  .txt {
    -webkit-line-clamp: unset;
    padding-top: 0.5em;
    line-height: 1.4;
    &-body {
      margin-top: 0.3em;
      word-break: break-all;
      white-space: pre-wrap;
    }
    .date {
      margin: 0.3em 0 0 0;
      color: rgb(107, 107, 107);
      font-size: 1.3rem;
    }
  }
  .img {
    position: relative;
    text-align: center;
    margin: 2rem 0;
    img {
      object-fit: contain;
      max-width: 100%;
    }
  }

}
.link-icon {
  position: absolute;
  bottom: 6px;
  right: 10px;
  font-weight: 700;
  @media screen and (max-width: 599px) {
    right: 6px;
  }
}
.vm--container::v-deep {
  @media screen and (max-width: 599px) {
    .vm--modal {
      left: 5% !important;
      width: 90% !important;
    }
  }
}
</style>
