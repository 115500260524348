<template>
  <section class="index-comics" id="comics">
    <h2 class="heading-img">
      <img src="@/assets/img/heading_comics.png" alt="comics" />
    </h2>
    <div class="bn">
      <div class="sp" @click="$modal.show('comics-modal')">
        <img
          src="@/assets/img/comics/itomanman_comic01.jpg"
          alt="コミックス1ページ目"
          class="pure-img"
        />
      </div>
      <div class="pc" @click="$modal.show('comics-modal')">
        <div class="flex">
          <img
            src="@/assets/img/comics/itomanman_comic01.jpg"
            alt="コミックス1ページ目"
          />
          <img
            src="@/assets/img/comics/itomanman_comic02.jpg"
            alt="コミックス2ページ目"
          />
        </div>
      </div>
    </div>
    <modal
      class="comics-modal"
      name="comics-modal"
      :adaptive="true"
      height="auto"
      width="90%"
      :max-width="1200"
      :scrollable="false"
      transition="fade"
      @before-open="modal.isOpened = true"
      @before-close="modal.isOpened = false"
    >
      <button
        type="button"
        class="modal-close"
        @click="$modal.hide('comics-modal')"
      >
        <font-awesome-icon :icon="['fas', 'times-circle']"></font-awesome-icon>
      </button>
      <div v-scroll-lock="modal.isOpened">
        <agile
          :options="slideOptions"
          @after-change="(e) => (currentSlide = e.currentSlide)"
        >
          <div class="slide" v-for="(src, index) in slides" :key="index">
            <img v-lazy="src" />
          </div>
          <template slot="caption"
            ><span class="page-count">{{ pageCount }}</span></template
          >
          <template slot="prevButton">
            <font-awesome-icon :icon="['fas', 'chevron-left']"></font-awesome-icon>
          </template>
          <template slot="nextButton">
            <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
          </template>
        </agile>
      </div>
    </modal>
  </section>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "Comics",
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      items: [],
      modal: {
        isOpened: false,
      },
      slides: [
        require("@/assets/img/comics/itomanman_comic01.jpg"),
        require("@/assets/img/comics/itomanman_comic02.jpg"),
        require("@/assets/img/comics/itomanman_comic03.jpg"),
        require("@/assets/img/comics/itomanman_comic04.jpg"),
        require("@/assets/img/comics/itomanman_comic05.jpg"),
        require("@/assets/img/comics/itomanman_comic06.jpg"),
        require("@/assets/img/comics/itomanman_comic07.jpg"),
        require("@/assets/img/comics/itomanman_comic08.jpg"),
        require("@/assets/img/comics/itomanman_comic09.jpg"),
        require("@/assets/img/comics/itomanman_comic10.jpg"),
        require("@/assets/img/comics/itomanman_comic11.jpg"),
        require("@/assets/img/comics/itomanman_comic12.jpg"),
        require("@/assets/img/comics/itomanman_comic13.jpg"),
        require("@/assets/img/comics/itomanman_comic14.jpg"),
        require("@/assets/img/comics/itomanman_comic15.jpg"),
        require("@/assets/img/comics/itomanman_comic16.jpg"),
      ],
      slideOptions: {
        autoplay: false,
        fade: false,
        dots: false,
        centerMode: false,
        navButtons: true,
        infinite: true,
        slidesToShow: 1,
      },
      currentSlide: 0,
    };
  },
  computed: {
    pageCount() {
      return `${this.currentSlide + 1} / ${this.slides.length}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.index-comics {
  .bn {
    img {
      cursor: pointer;
    }
    .flex {
      display: flex;
      img {
        width: 50%;
      }
    }
  }
}
.comics-modal {
  .slide img {
    max-height: 100vh;
    display: block;
    margin: auto;
  }
  .modal-close {
    right: 15%;
    z-index: 100;
    @media screen and (max-width: 1024px) {
      right: 10px;
    }
    @media screen and (max-width: 599px) {
      right: 6px;
    }
  }
  .page-count {
    position: absolute;
    bottom: 3px;
    right: 15%;
    font-weight: 700;
    cursor: pointer;
    z-index: 100;
    @media screen and (max-width: 1024px) {
      right: 10px;
    }
    @media screen and (max-width: 599px) {
      right: 6px;
    }
  }
}
.vm--container::v-deep {
  .vm--modal {
    background-color: transparent;
    @media screen and (max-width: 599px) {
      width: 100% !important;
      left: 0px !important;
    }
  }
}
.agile::v-deep {
  .agile__nav-button {
    border: none;
    color: black;
    cursor: pointer;
    font-size: 24px;
    height: 50%;
    position: absolute;
    top: 25%;
    transition-duration: 0.3s;
    width: 60px;
    background-color: transparent;
    opacity: 0.8;
    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }
  }
}
</style>
